
export default function Works() {
  const data = [
    {
      id: "1",
      title: "Quality",
      desc:
        "Certified with ISO 9001:2008 Quality Management system by QA International Certification Ltd",
     },
    {
      id: "2",
      title: "Reliability",
      desc:
        "Timely Delivery. In-house Manufacturing. Well Maintained and Handy Stock of Raw Materials ",
       
    },
    {
      id: "3",
      title: "Trust",
      desc:
        "Always focused on Customer needs and satisfaction. We suppl to almost all reputed Petrochemicals, Offshores,water treatment plants, Valve manufacturers",
      
    },
  ];

  
  
  return (
    <div className="bg-[#08193e] h-[calc(100vh-70px)] sm:h-auto md:h-auto  flex flex-col  place-items-center 
    place-content-center  " id="about">
      <div className="flex flex-col  place-items-center place-content-center sm:pr-10 ">
      <h1 className="text-[50px] text-white">About Us</h1>
      <div className="flex xlg:space-x-8 lg:space-x-8 sm:flex-col md:flex-col">
        {data.map((d) => (
          <div className="">
               
                <div className="">
                  <div className="bg-white rounded-[50px] h-[300px] p-[10%] m-[5%] w-[300px]">

                  <h2 className="text-crimson text-[30px]">{d.title}</h2>
                  {/* <p>{d.desc}</p> */}
                  <h1>{d.desc} </h1>
                   
                  </div>
                 
                </div>
              
             
             
          </div>
        ))}
      </div>
      </div>
    </div>
  );
}
