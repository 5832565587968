import { useEffect, useState } from "react";
import PortfolioList from "../portfolioList/PortfolioList";
import "./portfolio.scss";
import {
  studBolts,hexBolts,hexNuts,foundationalBolts
} from "../../data";

export default function Portfolio() {
  const [selected, setSelected] = useState("sb");
  const [data, setData] = useState([]);
  const list = [
    {
      id: "sb",
      title: "Stud Bolts",
    },
    {
      id: "hb",
      title: "Hexagonal Bolts",
    },
    {
      id: "hn",
      title: "Hexagonal Nuts",
    },
    {
      id: "fb",
      title: "Foundational Bolts",
    },
    
  ];

  useEffect(() => {
    switch (selected) {
      case "sb":
        setData(studBolts);
        break;
      case "hb":
        setData(hexBolts);
        break;
      case "hn":
        setData(hexNuts);
        break;
      case "fb":
        setData(foundationalBolts);
        break;
      default:
        setData(studBolts);
    }
  }, [selected]);

  return (
    <div className="portfolio" id="portfolio">
      <h1>Our Products</h1>
      <ul>
        {list.map((item) => (
          <PortfolioList
            title={item.title}
            active={selected === item.id}
            setSelected={setSelected}
            id={item.id}
          />
        ))}
      </ul>
      <div className="container">
        {data.map((d) => (
          <div className="item">
            <img
              src={d.img}
              alt=""
            />
            {/* <h3>{d.title}</h3> */}
          </div>
        ))}
      </div>
    </div>
  );
}
