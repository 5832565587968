import React from 'react'

function Topbar() {
  return (
    <div className='bg-white w-[100%] h-[70px] sticky z-3 top-0 left-0 right-0 flex justify-between align-middle px-20 py-5 sm:px-1 sm:w-[95%]'>
      <img src="assets/Logo.png" alt="logo" className='w-14  h-14 cursor-pointer top-2 left-20 absolute sm:left-2' />
       <div className="flex ">
      {/* <h1></h1> */}
        </div> 

      <div className='flex'>
        <ul className='flex space-x-4'>
            <li className='hover:text-crimson hover:drop-shadow-xl cursor-pointer' ><a href="#intro">Home</a></li>
            <li className='hover:text-crimson hover:drop-shadow-xl cursor-pointer'><a href="#portfolio">Products</a></li>
            <li className='hover:text-crimson hover:drop-shadow-xl cursor-pointer'><a href="#about">About</a></li>
            <li className='hover:text-crimson hover:drop-shadow-xl cursor-pointer'><a href="#contact">Contact</a></li>
        </ul>
      </div>
    </div>
  )
}

export default Topbar
