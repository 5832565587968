import React ,{ useEffect, useRef } from 'react'
import { init } from "ityped";

function Intro() {
    const textRef = useRef();

    useEffect(() => {
      init(textRef.current, {
        showCursor: true,
        backDelay: 1500,
        backSpeed:60,
        // strings: ["Secure", "Reliable", "Instant"],
        strings: ["Quality", "Reliability", "Trust"],
  
      });
    }, []);
  return (
    <div className=" sm:w-[100vw]
    h-[calc(100vh-70px)] bg-gradient-to-r from-[#002375] to-[#08142d] flex flex-col place-items-center place-content-center "
    id="intro" >
        <div className="flex flex-col xlg:place-items-center xlg:place-content-center
        lg:place-items-center lg:place-content-center md:place-items-center md:place-content-center
         space-y-2 sm:px-10 " >

        <h1 className='text-white text-[50px] sm:text-[40px]'>HI-TECH PROCESS EQUIPMENTS</h1>
        <h2 className='text-[#F8CF40] text-[25px] sm:text-[20px] sm:w-[80%]'>Manufacturers & Exporters of High Tensile Fastners</h2>
        <h3 className='text-[#D4F1F4] text-[20px] sm:text-[18px]'><span ref={textRef}></span></h3>
        </div>
      

    </div>
  )
}

export default Intro
