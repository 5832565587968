import React from "react";
import Topbar from "./components/Topbar";
import Intro from "./components/Intro";
import Portfolio from "./components/portfolio/Portfolio";
import Works from "./components/Works";
import Contact from "./components/contact/Contact";
function App() {
  return (
    <div className="bg-white font-semibold  w-[100%] sm:overflow-x-hidden ">

       <Topbar/>
      <Intro/>
      <Portfolio/>
      <Works/>
      <Contact/>
     </div>
  );
}


export default App;
