import "./contact.scss";
import LocationOnIcon from '@mui/icons-material/LocationOn';
import PersonIcon from '@mui/icons-material/Person';
import PhoneIcon from '@mui/icons-material/Phone';
import MailIcon from '@mui/icons-material/Mail';
export default function Contact() {

  return (
    <div className="contact" id="contact">
      <div className=" flex-1 overflow-hidden">
        <img src="assets/shake.svg" className="h-auto" alt="" />
      </div>
      <div className="flex flex-col flex-1 lg:ml-10 xlg:ml-10 justify-center space-y-4 sm:items-center md:items-center ">
        <h2 className="text-[30px]">Contact.</h2>
        <div className="flex items-start ">
            <PersonIcon className="mr-1" />
            <span className="text-[15px]">Vijay Singh</span>
          </div>
          <div className="flex items-start">
            <MailIcon className="mr-1" />
            <span className="text-[15px] hover:text-crimson">
              <a href="mailto:vijay@hitechprocess.in" className="hover:text-crimson">
              vijay@hitechprocess.in
              </a>
              </span>
          </div>
          <div className="flex items-start">
            <PhoneIcon className="mr-1" />
            <span className="text-[15px] hover:text-crimson ">
              <a href="tel:+91 9892485527" className="hover:text-crimson">+91 9892485527</a>
              {/* +91 9892485527 /  0251-2620326 */}
              </span>
          </div>
          <div className="flex items-start justify-center">
            <LocationOnIcon className="mr-1" />
            <span className="text-[15px] hover:text-crimson" >
             <a href="https://goo.gl/maps/8GCn9D7yp9G2HGoX6" target="_blank" rel="noreferrer">Plot No.W-81, MIDC,Anand Nagar, Ambernath(E)-421506, Thane Maharashtra.</a> 
              </span>
 </div>
 <div className="">

<iframe title="myframe"
src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3768.2752890589704!2d73.19120491449823!3d19.183174887026535!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3be7935d502a006f%3A0x301f25eec21e6cc!2sHi-Tech%20Process%20Equipments!5e0!3m2!1sen!2sin!4v1662187997848!5m2!1sen!2sin" 
width="450" height="250" 
style={{border:'0'}} allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade">

</iframe>
  </div>
      </div>
    </div>
  );
}
