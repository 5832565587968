export const studBolts = [
    {
      id: 1,
      title: "Social Media App",
      img: "assets/sb1.jpg"
    },
    {
      id: 2,
      title: "Rampa UI Design",
      img:
      "assets/sb2.jpg"    },
    {
      id: 3,
      title: "E-commerce Web Design",
      img:
      "assets/sb3.jpg"    },
    {
      id: 4,
      title: "Relax Mobile App",
      img:
      "assets/sb4.jpg"    },
    
  ];
  
  export const hexBolts = [
    {
      id: 1,
      title: "Web Social Media App",
      img:  
      "assets/hb1.jpg"    },
    {
      id: 2,
      title: "Web Rampa UI Design",
      img: 
      "assets/hb2.jpg"    },
    {
      id: 3,
      title: "Web E-commerce Design",
      img: 
      "assets/hb3.jpg"    },
    {
      id: 4,
      title: "Web Relax App",
      img: 
      "assets/hb4.jpg"    },
     
  ];
  
  export const hexNuts = [
    {
      id: 1,
      title: "Mobile Social Media App",
      img:
      "assets/hn1.jpg"    },
    {
      id: 2,
      title: "Mobile Rampa UI Design",
      img:
      "assets/hn2.jpg"    },
    {
      id: 3,
      title: "Mobile E-commerce Design",
      img:
      "assets/hn3.jpg"    },
    {
      id: 4,
      title: "Mobile Relax App",
      img:
      "assets/hn4.jpg"    },
     
  ];
  
  export const foundationalBolts = [
    {
      id: 1,
      title: "Design Social Media App",
      img:
      "assets/fb1.jpg"    },
    {
      id: 2,
      title: "Design Rampa UI Design",
      img:
      "assets/fb2.jpg"    },
    {
      id: 3,
      title: "Design E-commerce Web Design",
      img:
      "assets/fb3.png"    },
    {
      id: 4,
      title: "Design Relax Mobile App",
      img:
      "assets/fb4.jpg"    },
       ];
  